<template>
  <v-skeleton-loader type="card" :loading="!association">
    <v-card color="white" :loading="!association" tile>
      <template v-if="association">
        <v-card-title
          v-if="!no_header"
          class="pa-2 secondary white--text subheading font-weight-bold"
        >
          {{ title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-row no-gutters>
            <v-col :cols="!no_break_point && $vuetify.breakpoint.xs ? 12 : 6">
              <v-list-item dense v-for="(item, index) in first" :key="index">
                <v-list-item-title>{{ index }}</v-list-item-title>
                <!--                <v-list-item-subtitle>{{-->
                <!--                  association[item]-->
                <!--                }}</v-list-item-subtitle>-->
                <v-list-item-subtitle
                  v-if="search.started"
                  v-html="parsedHtml(search.text, association[item])"
                />
                <v-list-item-subtitle v-else>{{
                  association[item]
                }}</v-list-item-subtitle>
              </v-list-item></v-col
            >
            <v-col :cols="!no_break_point && $vuetify.breakpoint.xs ? 12 : 6">
              <v-list-item dense v-for="(item, index) in second" :key="index">
                <v-list-item-title>{{ index }}</v-list-item-title>
                <v-list-item-subtitle
                  v-if="search.started"
                  v-html="parsedHtml(search.text, association[item])"
                />
                <v-list-item-subtitle v-else>{{
                  association[item]
                }}</v-list-item-subtitle>
              </v-list-item></v-col
            >
          </v-row>
        </v-list>
      </template>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top_association",
  computed: {
    ...mapGetters({ search: "search" })
  },
  data: () => ({
    first: {
      "Client Name": "Client_Associations",
      "Collection Attorney": "Collection Attorney",
      "Management Company": "Management Company"
    },
    second: {
      "Pre-Litigation Paralegal": "Pre-Litigation Paralegal",
      "General Attorney": "General Attorney",
      "Litigation Paralegal": "Litigation Paralegal"
    }
  }),
  props: {
    title: {
      type: String,
      default: "Association data"
    },
    no_header: {
      type: Boolean,
      default: true
    },
    no_break_point: {
      type: Boolean,
      default: false
    },
    association: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped></style>
